




































































































































import { getAllOrders } from "@/api/order-service";
import CoursePicker from "@/components/courses/CoursePicker.vue";
import OrderCard from "@/components/orders/OrderCard.vue";
import useAsyncData from "@/helpers/useAsyncData";
import Moment from "@/services/moment";
import { stringTimeToUnix } from "@/utils/timeUtils";
import { computed, defineComponent, provide, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "AllOrders",
  components: { CoursePicker, OrderCard },
  setup() {
    const { data: orders, isLoading, refresh } = useAsyncData(() => getAllOrders());
    const vm = reactive({
      loading: false,
      filters: {
        courseIDs: [] as string[],
        period: [] as string[],
        periodString: "",
        status: [] as string[],
        startTimeRange: [] as string[],
        startTimeRangeString: "",
      },
      periodMenu: false,
      startTimeRangeMenu: false,
      items: [
        { value: "paid", text: "Betalt" },
        { value: "unpaid", text: "Ikke betalt" },
        { value: "past due", text: "Forfalt" },
        { value: "cancelled", text: "Kansellert" },
        { value: "unknown status", text: "Ukjent status" },
        { value: "no invoiceItem", text: "Ikke fakturert" },
      ],
    });

    const filteredOrders = computed(() =>
      orders.value?.filter(periodFilter)
        .filter(statusFilter)
        .filter(courseFilter)
        .filter(startTimeFilter)
    );

    function periodFilter(order: Learnlink.Order.View.Admin.Thumbnail): boolean {
      if (vm.filters.period.length) {
        return order.created > Moment.unix(stringTimeToUnix(vm.filters.period[0], "00:00")).startOf("day").unix() &&
          order.created < Moment.unix(stringTimeToUnix(vm.filters.period[1], "00:00")).endOf("day").unix();
      }
      return true;
    }

    function startTimeFilter(order: Learnlink.Order.View.Admin.Thumbnail): boolean {
      if (vm.filters.startTimeRange.length) {
        return order.startTime > Moment.unix(stringTimeToUnix(vm.filters.startTimeRange[0], "00:00")).startOf("day").unix() &&
          order.startTime < Moment.unix(stringTimeToUnix(vm.filters.startTimeRange[1], "00:00")).endOf("day").unix();
      }
      return true;
    }

    function courseFilter(order: Learnlink.Order.View.Admin.Thumbnail): boolean {
      if (vm.filters.courseIDs.length) {
        return vm.filters.courseIDs.includes(order.courseID);
      }
      return true;
    }

    function statusFilter(order: Learnlink.Order.View.Admin.Thumbnail): boolean {
      if (vm.filters.status.length) {
        return (order.invoice?.status === "paid" && order.invoice?.amountPaid === 0 && vm.filters.status.includes("cancelled")) ||
          (order.invoice?.status === "paid" && order.invoice?.amountPaid > 0 && vm.filters.status.includes("paid")) ||
          (order.invoice?.status === "open" && order.invoice?.dueDate < Moment().unix() && vm.filters.status.includes("past due")) ||
          (order.invoice?.status === "open" && order.invoice?.dueDate > Moment().unix() && vm.filters.status.includes("unpaid")) ||
          (!["paid", "open"].includes(order.invoice?.status || "") && vm.filters.status.includes("unknown status")) ||
          (!order.invoiceItemID && vm.filters.status.includes("no invoiceItem"));
      }
      return true;
    }

    provide("updateOrder", (uid: string, order: Partial<Learnlink.Order.View.Admin.Thumbnail> | null) => {
      if (!orders.value) return;

      if (order) {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          const clone = [...orders.value];
          clone[index] = { ...orders.value[index], ...order };
          orders.value = clone;
        }
      }
      else {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          orders.value.splice(index, 1);
        }
      }
    });

    async function mount(): Promise<void> {
      vm.loading = true;
      await refresh();
      vm.loading = false;
    }

    function getTotal(): number {
      return (filteredOrders.value?.reduce((a, b) => a + (b.invoice?.total || 0), 0) || 0) / 100;
    }

    return {
      filteredOrders,
      isLoading,
      getTotal,
      mount,
      vm,
    };
  },
});
