






































































































































































import { createInvoiceItem, updateOrder } from "@/api/order-service";
import Loader from "@/components/Loader.vue";
import CancelOrderDialog from "@/components/orders/CancelOrderDialog.vue";
import ChangeOrderDialog from "@/components/orders/ChangeOrderDialog.vue";
import HandleOrderDialog from "@/components/orders/HandleOrderDialog.vue";
import { copyToClipboard } from "@/helpers/copyToClipboard";
import { sanitizeError } from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import { computed, defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "OrderCard",
  components: { CancelOrderDialog, ChangeOrderDialog, HandleOrderDialog, Loader },
  props: {
    order: {
      type: Object as PropType<Learnlink.Order.View.Admin.Thumbnail>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      cancelOrderDialogVisible: false,
      changeOrderDialogVisible: false,
      handleOrderDialogVisible: false,
      loading: false,
      invoiceItemLoading: false,
      orderNote: "",
      // studentNote: "",
    });

    const updateOrderInject = inject<(uid: string, order: Partial<Learnlink.Order.View.Admin.Thumbnail>|null) => void>("updateOrder");

    const orderButtonText = computed(() => {
      return props.order.invoiceItemID
        ? props.order.invoiceID
          ? "Fakturert"
          : "Faktureres"
        : "Fakturer";
    });

    onMounted(() => {
      vm.loading = true;
      // vm.studentNote = props.order.student?.note;
      vm.orderNote = props.order.note;
      vm.loading = false;
    });

    const courseType = computed(() => `${props.order.course.location} ${props.order.course.weeks === "1" ? "1 uke" : props.order.course.weeks + " uker"}`);
    const readableOrderDate = computed(() => Moment.unix(props.order.created).format("lll"));

    const openEditCustomerDialog = inject<(customerUID: string) => void>("openEditCustomerDialog");

    async function create(): Promise<void> {
      vm.invoiceItemLoading = true;
      try {
        const order = await createInvoiceItem(props.order.ID, props.order.customerUID, props.order.courseID, props.order.course.title);
        updateOrderInject && updateOrderInject(props.order.ID, order);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Faktureres! 🥳",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: sanitizeError(e).message,
        });
      }
      vm.invoiceItemLoading = false;
    }

    // async function updateStudentNote(): Promise<void> {
    //   try {
    //     if (props.order.studentUID) {
    //       await updateUser({
    //         uid: props.order.studentUID,
    //         notes: vm.studentNote,
    //       });
    //     }
    //     await store.dispatch("displayAlert", {
    //       color: "success",
    //       message: "Oppdatert! 🥳",
    //     });
    //   }
    //   catch (e) {
    //     await store.dispatch("displayAlert", {
    //       color: "error",
    //       message: e,
    //     });
    //   }
    // }

    async function updateOrderNote(): Promise<void> {
      try {
        await updateOrder({
          ID: props.order.ID,
          note: vm.orderNote,
        });
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Team oppdatert! 🥳",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
    }

    function getReadableStatus(): string {
      return props.order.invoice?.status === "paid" && props.order.invoice?.amountPaid === 0
        ? "Kreditert"
        : props.order.invoice?.status === "paid"
          ? "Betalt"
          : props.order.invoice?.status === "open" && props.order.invoice?.dueDate < Moment().unix()
            ? "Forfalt"
            : props.order.invoice?.status === "open"
              ? "Ikke betalt"
              : "Ukjent status";
    }

    function getStatusColors(): string {
      return props.order.invoice?.status === "paid" && props.order.invoice?.amountPaid === 0
        ? "grey"
        : props.order.invoice?.status === "paid"
          ? "green"
          : props.order.invoice?.status === "open" && props.order.invoice?.dueDate < Moment().unix()
            ? "red"
            : props.order.invoice?.status === "open"
              ? "goldenrod"
              : "ligt-grey";
    }

    function openInvoiceItem() {
      if (props.order.invoiceID) open(`https://dashboard.stripe.com/invoices/${props.order.invoiceID}`, "_blank");
      else if (props.order.invoiceItemID) open(`https://dashboard.stripe.com/search?query=${props.order.invoiceItemID}`, "_blank");
    }

    return {
      copyToClipboard,
      courseType,
      create,
      getReadableStatus,
      getStatusColors,
      openEditCustomerDialog,
      openInvoiceItem,
      orderButtonText,
      readableOrderDate,
      updateOrderNote,
      // updateStudentNote,
      vm,
    };
  },
});
