import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/orders";

export async function getAllOrders(): Promise<Learnlink.Order.View.Admin.Thumbnail[]> {
  const authRes = await axios.get<Learnlink.Order.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/all`,
  );
  return authRes.data;
}

export async function getUnhandledOrders(): Promise<Learnlink.Order.View.Admin.Thumbnail[]> {
  const authRes = await axios.get<Learnlink.Order.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/unhandled`,
  );
  return authRes.data;
}

export async function getOrdersForCustomer(uid: string): Promise<Learnlink.Order.View.Admin.Thumbnail[]> {
  const authRes = await axios.get<Learnlink.Order.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/${uid}`,
  );
  return authRes.data;
}

export async function createOrder(order: Learnlink.Order.Create): Promise<Learnlink.Order.Order> {
  const authRes = await axios.post<Learnlink.Order.Order>(
    ENDPOINT, order
  );
  return authRes.data;
}

export async function updateOrder(order: Learnlink.Order.Update): Promise<Learnlink.Order.Order> {
  const authRes = await axios.put<Learnlink.Order.Order>(
    `${ENDPOINT}/${order.ID}`, order
  );
  return authRes.data;
}

export async function changeOrder(order: Learnlink.Order.Update): Promise<Learnlink.Order.Order> {
  const authRes = await axios.put<Learnlink.Order.Order>(
    `${ENDPOINT}/changeOrder`, order
  );
  return authRes.data;
}

export async function cancelOrder(orderID: string, invoiceItemID: string):Promise<void> {
  if (invoiceItemID) {
    await axios.delete<void>(
      `${ENDPOINT}/${orderID}/${invoiceItemID}`,
    );
  }
  else {
    await axios.delete<void>(
      `${ENDPOINT}/${orderID}`,
    );
  }
}

export async function createInvoiceItem(orderID: string, uid: string, courseID: string, courseTitle: string): Promise<Learnlink.Order.Order> {
  const orderRes = await axios.post<Learnlink.Order.Order>(
    `${ENDPOINT}/invoiceItem`, {
      uid,
      courseID,
      courseTitle,
      orderID,
    }
  );
  return orderRes.data;
}
