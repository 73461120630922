import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.isLoading)?_c(VToolbar,{staticClass:"mb-6",staticStyle:{"z-index":"100","position":"sticky","top":"20px"},attrs:{"flat":""}},[_c(VSpacer),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.filteredOrders.length)+" bestillinger ")]),_c(VBtn,{attrs:{"x-large":"","icon":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.mount()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c('h2',{staticClass:"text-center"},[_vm._v(" Totalsum: "+_vm._s(_vm.getTotal())+" kr. ")]),_c(VSpacer)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{ref:"periodMenu",attrs:{"close-on-content-click":false,"return-value":_vm.vm.filters.periodString,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.vm.filters, "periodString", $event)},"update:return-value":function($event){return _vm.$set(_vm.vm.filters, "periodString", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Periode","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.vm.filters.period = []}},model:{value:(_vm.vm.filters.periodString),callback:function ($$v) {_vm.$set(_vm.vm.filters, "periodString", $$v)},expression:"vm.filters.periodString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.vm.periodMenu),callback:function ($$v) {_vm.$set(_vm.vm, "periodMenu", $$v)},expression:"vm.periodMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","range":""},on:{"change":function($event){_vm.$refs.periodMenu.save(_vm.vm.filters.period.join(' – '))}},model:{value:(_vm.vm.filters.period),callback:function ($$v) {_vm.$set(_vm.vm.filters, "period", $$v)},expression:"vm.filters.period"}})],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","multiple":"","items":_vm.vm.items,"label":"Status","clearable":""},model:{value:(_vm.vm.filters.status),callback:function ($$v) {_vm.$set(_vm.vm.filters, "status", $$v)},expression:"vm.filters.status"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('course-picker',{attrs:{"multiple":""},model:{value:(_vm.vm.filters.courseIDs),callback:function ($$v) {_vm.$set(_vm.vm.filters, "courseIDs", $$v)},expression:"vm.filters.courseIDs"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{ref:"startTimeRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.vm.filters.startTimeRangeString,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.vm.filters, "startTimeRangeString", $event)},"update:return-value":function($event){return _vm.$set(_vm.vm.filters, "startTimeRangeString", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Oppstart","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.vm.filters.startTimeRange = []}},model:{value:(_vm.vm.filters.startTimeRangeString),callback:function ($$v) {_vm.$set(_vm.vm.filters, "startTimeRangeString", $$v)},expression:"vm.filters.startTimeRangeString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.vm.startTimeRangeMenu),callback:function ($$v) {_vm.$set(_vm.vm, "startTimeRangeMenu", $$v)},expression:"vm.startTimeRangeMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","range":""},on:{"change":function($event){_vm.$refs.startTimeRangeMenu.save(_vm.vm.filters.startTimeRange.join(' – '))}},model:{value:(_vm.vm.filters.startTimeRange),callback:function ($$v) {_vm.$set(_vm.vm.filters, "startTimeRange", $$v)},expression:"vm.filters.startTimeRange"}})],1)],1)],1),(_vm.isLoading)?_c('div',{staticClass:"text-center my-16"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._l((_vm.filteredOrders),function(order){return _c('order-card',{key:order.ID,staticClass:"mb-2",attrs:{"order":order}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }